import cx from 'clsx';
import { getToggleSort, renderColumn, renderSortIcons } from 'helpers/table';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import omit from 'lodash/omit';
import router, { useRouter } from 'next/router';
import { useState } from 'react';
import { ColumnSort } from 'types/table';
import { Sort } from 'v2.api/src/common-generic/enum';
import { ColumnsType, ColumnType } from 'v2.api/src/common-generic/types/table';

import Spinner from './spinner';
import Tooltip from './tooltip';

export interface Props<T> {
  fieldId?: string;
  dataSource: Array<T>;
  rowKey: string;
  isLoading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnsType<T, any>;
  onClickRow?: (row: T) => Promise<string | void> | (string | void);
  shouldOpenNewTab?: boolean;
  onSort?: (column: ColumnSort) => void;
  className?: string;
  selectedRowIds?: Array<string>;
  highlightedRowIds?: Array<string>;
  selectedValues?: Array<T>;
  onHoverRow?: (row: T) => string | void;
  isRounded?: boolean;
}

interface QueryParams extends Partial<ColumnSort> {
  actions_sub_route?: Array<string>;
  pageSize?: string;
}

function Table<T>({
  fieldId = '',
  dataSource,
  rowKey,
  isLoading,
  columns,
  onClickRow,
  shouldOpenNewTab,
  onSort,
  className,
  selectedRowIds,
  highlightedRowIds,
  selectedValues,
  onHoverRow,
  isRounded = true,
}: Props<T>) {
  const { push, query } = useRouter();
  const {
    actions_sub_route = [],
    sortKey,
    sort,
    pageSize,
  }: QueryParams = query;

  const [actionId] = actions_sub_route;

  const [sorts, setSorts] = useState<Array<Sort>>(
    map(columns, ({ key }) => (key === sortKey ? sort : undefined)),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnSort = (column: ColumnType<T, any>, idx: number) => {
    if (!column.isSortable) return;

    setSorts((prevSorts) => {
      const newSort = getToggleSort(prevSorts[idx]);

      onSort?.({ sortKey: column.key, sort: newSort });

      const restQuery = omit(query, ['sortKey', 'sort']);

      push({
        query: {
          ...restQuery,
          ...(newSort && { sort: newSort, sortKey: column.key }),
        },
      });

      return map(prevSorts, (_, sortIdx) =>
        sortIdx === idx ? newSort : undefined,
      );
    });
  };

  const getTdClassnames = (rowId: number, colIdx: number) => {
    if (!isRounded) {
      return rowId === 0 ? 'border-y' : 'border-b';
    }

    const isFirstRow = rowId === 0;
    const isLastRow = rowId === dataSource.length - 1;
    const isFirstCol = colIdx === 0;
    const isLastCol = colIdx === columns.length - 1;

    if (isFirstRow && isLastRow) {
      if (isFirstCol) return 'rounded-l-lg border-y border-l';
      if (isLastCol) return 'rounded-r-lg border-y border-r';
      return 'border-y';
    }

    if (isFirstRow) {
      if (isFirstCol) return 'rounded-tl-lg border-y border-l';
      if (isLastCol) return 'rounded-tr-lg border-y border-r';
      return 'border-y';
    }

    if (isLastRow) {
      if (isFirstCol) return 'rounded-bl-lg border-b border-l';
      if (isLastCol) return 'rounded-br-lg border-b border-r';
      return 'border-b';
    }

    if (isFirstCol) return 'border-b border-l';
    if (isLastCol) return 'border-b border-r';
    return 'border-b';
  };

  return (
    <div className="w-[calc(100vw-1rem)] overflow-x-auto md:w-auto md:overflow-x-visible">
      <table
        id={fieldId}
        className={cx('w-full border-separate border-spacing-0', className)}
      >
        <thead>
          <tr className="h-10 w-full items-center space-x-4 text-left text-text-4">
            {map(columns, (column, idx) => (
              <th
                key={`${column.title}-${idx}`}
                className={column.headerClassName}
                onClick={() => handleOnSort(column, idx)}
              >
                <div
                  className={cx({
                    'inline-flex cursor-pointer select-none items-center space-x-2':
                      column.isSortable,
                  })}
                >
                  {column.renderHeader?.() || (
                    <p className="font-medium text-text-2">{column.title}</p>
                  )}

                  {column.isSortable && renderSortIcons(sorts, idx)}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        {!isLoading && (
          <tbody>
            {map(dataSource, (data, rowIdx) => {
              const computedRowKey = get(data, rowKey);

              return (
                <tr
                  key={computedRowKey}
                  role={onClickRow ? 'button' : 'row'}
                  className={cx(
                    'h-16 space-x-4 rounded-lg px-4',
                    { 'cursor-pointer': onClickRow },
                    {
                      'last:border-b':
                        dataSource.length !== Number(pageSize || 10),
                    },
                    {
                      'bg-surface-17': actionId
                        ? String(computedRowKey) === actionId
                        : find(
                            selectedRowIds,
                            (id) => String(computedRowKey) === id,
                          ),
                    },
                    {
                      'bg-surface-2': actionId
                        ? String(computedRowKey) !== actionId
                        : !find(
                            selectedRowIds,
                            (id) => String(computedRowKey) === id,
                          ),
                    },
                    {
                      'bg-surface-8': find(
                        selectedValues,
                        (elem) => elem[rowKey] === computedRowKey,
                      ),
                    },
                    {
                      'bg-surface-4':
                        highlightedRowIds?.includes(computedRowKey),
                    },
                  )}
                  onMouseEnter={() => {
                    onHoverRow?.(data);
                  }}
                  onClick={async (event) => {
                    if (!onClickRow) return;

                    const url = (await onClickRow?.(data)) as string;

                    if (!url) return;

                    if (event.metaKey || event.ctrlKey || shouldOpenNewTab) {
                      const win = window.open(url, '_blank', 'noopener');
                      win?.focus();
                    } else {
                      router.push(url);
                    }
                  }}
                >
                  {map(
                    columns,
                    ({ render, key, className, hasTooltip = false }, idx) => (
                      <td
                        key={`${key}-${idx}`}
                        className={cx(
                          className,
                          'truncate border-border-2 align-middle',
                          getTdClassnames(rowIdx, idx),
                        )}
                      >
                        {hasTooltip ? (
                          <Tooltip label={data[key]}>
                            {renderColumn(render, data, idx, key)}
                          </Tooltip>
                        ) : (
                          renderColumn(render, data, idx, key)
                        )}
                      </td>
                    ),
                  )}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {isLoading && (
        <div className="mt-20 flex w-full items-center justify-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default Table;
