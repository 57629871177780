import { capitalize, split, toInteger } from 'lodash';
import { ToWords } from 'to-words';

const toWords = new ToWords({ localeCode: 'fr-FR' });

const getNumberAsWords = (number: number | string): string =>
  toWords.convert(Number(number)).toLowerCase();

export const numberToWords = (number: number): string => {
  return getNumberAsWords(number);
};

export const numberToCapitalizedWords = (number: number): string => {
  return capitalize(getNumberAsWords(number));
};

export const priceToWords = (number: number): string => {
  if (number.toString().includes('.')) {
    const [whole, rawDecimal] = split(number.toString(), '.');
    const decimal =
      rawDecimal.length === 1
        ? (Number(rawDecimal) * 10).toString()
        : rawDecimal;

    if (whole === '0') {
      return `${getNumberAsWords(decimal)} ${pluralize(
        decimal,
        'centime',
      )} d’euro`;
    }

    return `${getNumberAsWords(whole)} ${pluralize(
      whole,
      'euro',
    )} et ${getNumberAsWords(decimal)} ${pluralize(decimal, 'centime')}`;
  }

  return `${getNumberAsWords(number)} ${pluralize(number.toString(), 'euro')}`;
};

export const formatPrice = (param: string): string => {
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  });

  return formatter.format(Number(param));
};

export const formatPriceInternational = (param: string): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
  });

  return formatter.format(Number(param));
};

export const formatNumber = (param: string | number): string => {
  const formatter = new Intl.NumberFormat('fr-FR');

  return formatter.format(Number(param));
};

export const pluralize = (count: string, noun: string, suffix = 's') =>
  `${noun}${toInteger(count) > 1 ? suffix : ''}`;

export const getRoundedPercentage = (number: number): string =>
  (number * 100).toFixed(2);

export const decimalPointSeparator = (number: number): string => {
  const formatter = new Intl.NumberFormat('en-US');

  return formatter.format(Number(number));
};

export const formatNumberGermanSeparator = (param: string | number): string => {
  const formatter = new Intl.NumberFormat('de-DE');

  return formatter.format(Number(param));
};

export const formatPriceGermanSeparator = (param: string | number): string => {
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  return formatter.format(Number(param));
};

export const formatNumberToMonth = (number: number): string => {
  const date = new Date();
  date.setMonth(number - 1);

  return date.toLocaleString('fr-FR', { month: 'long' });
};
