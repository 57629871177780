/* eslint-disable max-lines */
import { parse, eval as evaluate } from 'expression-eval';
import {
  filter,
  find,
  findKey,
  flatten,
  isEmpty,
  isEqual,
  isNil,
  map,
  omit,
  reduce,
  some,
  split,
} from 'lodash';
import phone from 'phone';
import traverse from 'traverse';

import {
  Error as FieldError,
  Field,
  FieldBase,
  FieldValue,
  Fields,
  Option,
  Validator,
} from '../../common-generic/types/form';
import { SelectValues } from '../../common-generic/types/select';
import { isEmailValid } from '../../common-generic/validators';
import {
  ComputationLegacy,
  Coordinates,
  Element,
  Form,
  Operation,
  Variable,
  VariableTypes,
} from '../types/operation';

import { getTransformers } from './operations-transformers';
import { getWithDefaultValidators } from './validators';

interface ExtraVariables extends Record<string, unknown> {
  changedFieldCoordinates?: Coordinates;
}

export const getResultValue = <T>(value: T, type: VariableTypes) => {
  if (type === 'time' && typeof value === 'number') {
    const m = value % 60;

    const h = (value - m) / 60;

    return h.toString() + ':' + (m < 10 ? '0' : '') + m.toString();
  }

  if (type === 'date') {
    if (typeof value === 'number') {
      const date = new Date(value);

      if (isNaN(value)) return value;

      return date.toISOString().split('T')[0];
    }
  }

  if (type === 'days' && typeof value === 'number') {
    return (value / 24 / 3600 / 1000).toString();
  }

  if (type === 'hours' && typeof value === 'number') {
    return (value / 3600 / 1000).toString();
  }

  return value;
};

function getInitialValue<JSXElement>(
  field: FieldBase<unknown, JSXElement>,
  shouldGetOptionName: boolean,
) {
  const { value, options } = field;

  if (shouldGetOptionName) {
    return find(options, ({ id }: Option<JSXElement>) => id === value)?.name;
  }

  return value;
}

function getOptionValue<JSXElement>(
  field: FieldBase<unknown, JSXElement>,
  shouldGetOptionName = false,
) {
  const { value, fieldProps } = field;

  if (typeof value !== 'object')
    return getInitialValue(field, shouldGetOptionName);

  const selectValues = map(
    value as SelectValues<string, JSXElement>,
    shouldGetOptionName ? 'label' : 'value',
  );

  if (!fieldProps?.isMultiple) return selectValues[0];

  return selectValues;
}

export function getFieldValue<JSXElement>(
  field: FieldBase<unknown, JSXElement>,
  shouldGetOptionName: boolean,
  debugContext: string,
): FieldValue {
  if (!field) {
    throw Error(`field not found: ${debugContext}`);
  }

  const { type, value } = field;

  if (type === 'address') return value?.[0];

  if (type === 'phone-number' && typeof value === 'string') {
    return phone(value).phoneNumber || value;
  }

  if (!['select', 'radio'].includes(type)) return value;

  return getOptionValue(field, shouldGetOptionName) as FieldValue;
}

export function getFieldOrArrayFieldValue<JSXElement>(
  field: Field<JSXElement>,
  shouldGetOptionName: boolean,
  debugContext: string,
): FieldValue | Array<FieldValue> {
  const { type, rows } = field;

  if (type === 'array-field') {
    return map(flatten(rows), (row) =>
      getFieldValue(row, shouldGetOptionName, debugContext),
    );
  }

  return getFieldValue(field, shouldGetOptionName, debugContext);
}

export const getValue = (value: unknown, type: VariableTypes) => {
  // consider value === undefined as 0 hours and 0 minutes
  if (type === 'time' && (typeof value === 'string' || value === undefined)) {
    const [hours, minutes] = split(String(value), ':');

    return Number(hours || 0) * 60 + Number(minutes || 0);
  }

  if (
    type === 'date' &&
    (typeof value === 'string' || typeof value === 'number')
  ) {
    const numberValue = Number(value);

    if (!isNaN(numberValue)) return numberValue;

    return new Date(value).getTime();
  }

  return value;
};

export function getVariables<JSXElement>(
  debugContext: string,
  variables: Array<Variable>,
  expressionType: VariableTypes,
  operation: Operation<JSXElement>,
  extraVariables: Record<string, unknown>,
  transformableVariables: Record<string, unknown> = {},
): Record<string, unknown> {
  const values = map(
    variables,
    ({ identifier, coordinates, computeTransformer, shouldGetOptionName }) => {
      return {
        identifier,
        field: getFieldByCoordinates(debugContext, operation, coordinates),
        computeTransformer,
        shouldGetOptionName,
      };
    },
  );

  return {
    ...extraVariables,
    ...reduce(
      transformableVariables,
      (acc, value, key) => ({ ...acc, [key]: getValue(value, expressionType) }),
      {},
    ),
    ...reduce(
      values,
      (acc, { identifier, field, computeTransformer, shouldGetOptionName }) => {
        let variableValue = field
          ? getFieldOrArrayFieldValue(field, shouldGetOptionName, debugContext)
          : null;

        if (
          computeTransformer &&
          variableValue &&
          typeof variableValue === 'object'
        ) {
          variableValue = getTransformers[computeTransformer](
            variableValue as Array<FieldValue>,
          );
        }

        const value = getValue(variableValue, expressionType);

        return { ...acc, [identifier]: value };
      },
      {},
    ),
  };
}

export function getElementByCoordinates<JSXElement>(
  debugContext: string,
  operation: Partial<Operation<JSXElement>>,
  { stepId, sectionId, elementId }: Partial<Coordinates>,
): Element<JSXElement> {
  const step = find(operation.steps, { id: stepId });

  if (!step) {
    throw {
      message: 'Etape inexistante',
      debugContext,
      stepId,
      coordinates: {
        stepId,
        sectionId,
        elementId,
      },
    };
  }

  const section = find(step.sections, { id: sectionId });

  if (!section) {
    throw {
      message: 'Section inexistante',
      debugContext,
      stepId,
      coordinates: {
        stepId,
        sectionId,
        elementId,
      },
    };
  }

  const element = find(section.elements, { id: elementId });

  if (!element) {
    throw {
      message: 'Élément inexistant',
      debugContext,
      stepId,
      coordinates: {
        stepId,
        sectionId,
        elementId,
      },
    };
  }

  // no field id provided => looking for element
  return element;
}

export function getFormByCoordinates<JSXElement>(
  debugContext: string,
  operation: Partial<Operation<JSXElement>>,
  coordinates: Partial<Coordinates>,
): Form<unknown> {
  const element = getElementByCoordinates(debugContext, operation, coordinates);

  if (!['modal', 'form'].includes(element?.type)) return;

  if (element.type === 'form') {
    return element.form;
  }

  return element.modal.form;
}

export function getFieldByCoordinates<JSXElement>(
  debugContext: string,
  operation: Partial<Operation<JSXElement>>,
  coordinates: Partial<Coordinates>,
): Field<unknown> {
  const { fieldId } = coordinates;

  const form = getFormByCoordinates(debugContext, operation, coordinates);

  return find(form?.value, { id: fieldId });
}

export function isFocusedField<JSXElement>(
  debugContext: string,
  changedFieldCoordinates: Coordinates,
  focusedField: Coordinates,
  operationValue: Operation<JSXElement>,
) {
  const field = getFieldByCoordinates(
    debugContext,
    operationValue,
    focusedField,
  );

  if (!field) return false;

  const changedFieldCoordinatesWithoutId = omit(changedFieldCoordinates, 'id');
  const focusedFieldWithoutId = omit(focusedField, 'id');

  if (field.type !== 'array-field') {
    return isEqual(changedFieldCoordinatesWithoutId, focusedFieldWithoutId);
  }

  return (
    isEqual(changedFieldCoordinatesWithoutId, focusedFieldWithoutId) ||
    some(flatten(field.rows), ({ id }) =>
      isEqual(changedFieldCoordinatesWithoutId, {
        ...focusedFieldWithoutId,
        fieldId: id,
      }),
    )
  );
}

export const isTypeValid = (is: VariableTypes, variable: unknown) => {
  if (is === 'numeric') {
    return !isNaN(Number(variable));
  }

  if (is === 'email') {
    return isEmailValid(String(variable));
  }

  return true;
};

export function isConditionVerified<JSXElement>(
  debugContext: string,
  condition: ComputationLegacy,
  operationValue: Operation<JSXElement>,
  extraVariables: Record<string, unknown>,
  transformableVariables: Record<string, unknown> = {},
): boolean {
  if (!condition) return true;

  return (
    getComputationResult(
      debugContext,
      condition,
      operationValue,
      extraVariables,
      transformableVariables,
    ) === true
  );
}

export function getComputationResult<T, JSXElement>(
  debugContext: string,
  {
    variables,
    assertType,
    expressionType,
    targetValueType,
    expression,
    assertExists,
    focusedField,
    focusedFields,
  }: ComputationLegacy,
  operationValue: Operation<JSXElement>,
  extraVariables: ExtraVariables = {},
  transformableVariables: Record<string, unknown> = {},
) {
  const context = getVariables(
    debugContext,
    variables,
    expressionType,
    operationValue,
    extraVariables,
    transformableVariables,
  );

  const results = {};
  let isBooleanComputation = false;

  if (assertExists) {
    const variable = context[assertExists];
    results['assertExists'] = variable ? true : false;
    isBooleanComputation = true;
  }

  if (assertType) {
    const variable = context[Object.keys(context)[0]];

    results['assertType'] = isTypeValid(assertType, variable);
    isBooleanComputation = true;
  }

  if (focusedField && !extraVariables?.isTriggerAction) {
    results['focusedField'] = isFocusedField(
      debugContext,
      extraVariables.changedFieldCoordinates,
      focusedField,
      operationValue,
    );
    isBooleanComputation = true;
  }

  if (!isEmpty(focusedFields) && !extraVariables?.isTriggerAction) {
    results['focusedFields'] = some(focusedFields, (focusedField) =>
      isFocusedField(
        debugContext,
        extraVariables.changedFieldCoordinates,
        focusedField,
        operationValue,
      ),
    );
    isBooleanComputation = true;
  }

  if (expression) {
    try {
      const ast = parse(expression);

      results['expression'] = getResultValue<T>(
        evaluate(ast, context),
        targetValueType || expressionType,
      );
    } catch (error) {
      throw { error, expression };
    }
  }

  if (!isBooleanComputation) return results['expression'];

  return reduce(results, (acc, current) => acc && !!current, true);
}

const getStringPathFromArray = (path: Array<string>) =>
  reduce(
    path,
    (acc: string, current: string) => {
      if (acc === '') return current;

      if (isNaN(Number(current))) return `${acc}.${current}`;

      return `${acc}[${current}]`;
    },
    '',
  );

export const getInterpolated = (
  template: string,
  params: Record<string, unknown>,
  transformers?: Record<string, string>,
  debugInterpolationErrorToBuilder?: (error: string, template: string) => void,
): string => {
  if (!params) return;

  const names = Object.keys(params);

  const values = map(params, (param, key) => {
    const path = findKey(transformers, (_, transformerKey) => {
      const [partialPath] = split(transformerKey, '.');

      return partialPath === key;
    });

    if (
      transformers &&
      key &&
      transformers[path] &&
      path &&
      typeof param === 'object'
    ) {
      return traverse(param).map(function (item) {
        const currentPath = getStringPathFromArray(this.path);
        const fullPath = `${key}.${currentPath}`;

        if (transformers[fullPath]) {
          return getTransformers[transformers[fullPath]](item);
        }

        return item;
      });
    }

    if (transformers && key && transformers[key]) {
      return getTransformers[transformers[key]](param);
    }

    return param;
  });

  try {
    // empty names break interpolation, altho builders should avoid it, mistakes may happen
    const namesWithoutEmptyValues = map(
      names,
      (name) => name || '_unnamedVariable',
    );

    try {
      return new Function(
        ...namesWithoutEmptyValues,
        `return \`${template}\`;`,
      )(...values);
    } catch (error) {
      throw { error, template, params };
    }
  } catch (error) {
    if (process.env.NEXT_PUBLIC_ENV === 'production') {
      throw error;
    }

    debugInterpolationErrorToBuilder?.(error, template);
    throw error;
  }
};

export function getFieldErrors<JSXElement>(
  fields: Fields<JSXElement>,
): Array<FieldError> {
  if (!fields || isEmpty(fields)) return [];

  let errors = [];

  for (const field of fields) {
    const { id, isOptional, validators, type, value } = field;

    const isRichTextAreaEmpty =
      type === 'rich-textarea' &&
      (!value || value === '<p class="editor-paragraph"><br></p>');

    if (
      (isRichTextAreaEmpty ||
        isFieldEmpty(getFieldOrArrayFieldValue(field, false, id))) &&
      !isOptional
    ) {
      errors.push({
        fieldId: id,
        message: 'Champ obligatoire.',
      });

      continue;
    }

    if (isNil(value) || value === '') continue;

    const validatorsWithDefault = filter(
      getWithDefaultValidators(validators, type),
      (val) => !isNil(val),
    );

    const error = getFieldError(value, validatorsWithDefault, fields);

    if (!isEmpty(validatorsWithDefault) && error) {
      errors.push({
        fieldId: id,
        message: error,
      });

      continue;
    }

    errors = filter(errors, ({ fieldId }) => fieldId !== id);
  }

  return errors;
}

function getFieldError<JSXElement>(
  value: FieldValue,
  validators: Array<Validator>,
  fields: Fields<JSXElement>,
): string {
  if (isEmpty(validators)) return;

  let error: string;

  for (const validator of validators) {
    error = validator(value, fields);
    if (error) break;
  }
  return error;
}

export const isFieldEmpty = (
  value: FieldValue | Array<Partial<unknown>> | Partial<unknown>,
) => {
  if (value === undefined || value === null) return true;

  if (typeof value === 'string') return value === '';

  return false;
};
