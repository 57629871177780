import { chunk, map, sum, capitalize } from 'lodash';

import {
  formatDate,
  formatLongDate,
  formatDateWithDayAndMonth,
} from '../../common-generic/dates';
import {
  numberToWords,
  numberToCapitalizedWords,
  formatPrice,
  formatNumber,
  priceToWords,
  getRoundedPercentage,
  decimalPointSeparator,
  formatPriceInternational,
  formatNumberGermanSeparator,
  formatPriceGermanSeparator,
  formatNumberToMonth,
} from '../../common-generic/numbers';
import { lowercase, uppercase, siren } from '../../common-generic/strings';
import { FieldValue } from '../../common-generic/types/form';

const getSumIntervals = (param: Array<FieldValue>): number => {
  if (param.includes('') || param.includes(undefined)) return 0;

  return Math.ceil(
    sum(
      map(
        chunk(param, 2),
        (interval) =>
          new Date(interval[1] as string).getTime() -
          new Date(interval[0] as string).getTime() +
          86_400_000,
      ),
    ),
  );
};

const formatLongDateUpperCase = (date: string | number | Date) =>
  formatLongDate(date).toLocaleUpperCase();

export const getTransformers = {
  numberToWords,
  numberToCapitalizedWords,
  priceToWords,
  formatDate,
  formatLongDate,
  formatLongDateUpperCase,
  lowercase,
  uppercase,
  siren,
  capitalize,
  formatPrice,
  formatNumber,
  getSumIntervals,
  formatDateWithDayAndMonth,
  getRoundedPercentage,
  decimalPointSeparator,
  formatPriceInternational,
  formatNumberGermanSeparator,
  formatPriceGermanSeparator,
  formatNumberToMonth,
};

export const translatedTransformer = {
  numberToWords: 'Transformer un nombre en lettres',
  numberToCapitalizedWords:
    'Transformer un nombre en lettres (première lettre en majuscule)',
  priceToWords: 'Transformer un prix en lettres',
  formatDate: 'Formater une date (ex : 28/02/2023)',
  formatLongDate: 'Formater une date (ex : 28 février 2023)',
  formatLongDateUpperCase:
    'Formater une date en majuscule (ex : 28 FÉVRIER 2023)',
  formatDateWithDayAndMonth:
    'Formater une date avec le jour et le mois (ex : 12 juin)',
  lowercase: 'Afficher en minuscule',
  uppercase: 'Afficher en majuscule',
  siren: 'Afficher les caractères par groupe de 3',
  capitalize: 'Afficher la 1ère lettre en majuscule',
  formatPrice: 'Formater un prix',
  formatNumber: 'Formater un nombre (100000 devient 100 000)',
  formatNumberGermanSeparator:
    'Formater un nombre (100000.98 devient 100.000,98)',
  getSumIntervals: 'Obtenir l’intervale de sommes',
  getRoundedPercentage: 'Transformer un nombre en pourcentage',
  decimalPointSeparator: 'Formater un nombre (100000 devient 100,000.98)',
  formatPriceInternational: 'Formater un prix (100000.98 devient € 100,000.98)',
  formatPriceGermanSeparator:
    'Formater un prix (100000.98 devient 100.000,98 €)',
  formatNumberToMonth: 'Transformer un nombre en un mois (en lettres)',
};
