import { confirm } from 'components/confirmation-modal';
import noop from 'lodash/noop';
import traverse from 'traverse';
import { Document } from 'v2.api/src/common-business/types/document';
import { Coordinates } from 'v2.api/src/common-business/types/operation';

export const debugInterpolationErrorToBuilder = (
  error: string,
  template: string,
) => {
  confirm({
    title: 'Erreur d’interpolation',
    body: (
      <div className="w-400">
        <p>{'Dans la string : '}</p>
        <p className="font-bold">{template}</p>
        <p>{'Erreur : '}</p>
        <p className="font-bold">{error?.toString()}</p>
      </div>
    ),
    okText: 'Ok',
    onSubmit: noop,
  });
};

export const debugElementNotFoundErrorToBuilder = (
  title: string,
  debugContext: string,
  missingElementId: string,
  coordinates: Omit<Coordinates, 'id'>,
) => {
  confirm({
    title,
    body: (
      <div className="w-400">
        <p>{'Origine :'}</p>
        <p className="font-bold">{debugContext}</p>
        <p>{'Id :'}</p>
        <p className="font-bold">{missingElementId}</p>
        <p>{'Coordonnées : '}</p>
        <p className="font-bold">{JSON.stringify(coordinates)}</p>
      </div>
    ),
    okText: 'Ok',
    onSubmit: noop,
  });
};

export const getFlattenedDocuments = (
  template: Partial<Document>,
): Array<{ id: string; text: string }> => {
  const sentences = [];

  traverse(template).forEach((element: { id: string; text: string }) => {
    if (element?.text) {
      const { text } = element;

      sentences.push({ text, id: element?.id });
    }
  });

  return sentences;
};
