import Icon from 'components/icon';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { Sort } from 'v2.api/src/common-generic/enum';

export const getToggleSort = (preValue?: Sort) => {
  switch (preValue) {
    case undefined:
      return Sort.Asc;

    case Sort.Asc:
      return Sort.Desc;

    default:
      return undefined;
  }
};

export const renderSortIcons = (sorts: Array<Sort>, idx: number) => {
  const sort = sorts[idx];

  if (sort === Sort.Asc) {
    return <Icon size="sm" type="sort-desc" className="rotate-180" />;
  }

  if (sort === Sort.Desc) return <Icon size="sm" type="sort-desc" />;

  return <Icon size="sm" type="sort" className="fill-icon-5" />;
};

export function renderColumn<T>(
  render: (cell: T, key: string) => JSX.Element,
  data: T,
  id: number,
  key: string,
) {
  const renderer = render ? (
    render(data, `${key}-${id}`)
  ) : (
    <p className="truncate font-medium">{get(data, key)}</p>
  );

  if (renderer === undefined) return null;

  const EMPTY_COLUMN = <p className="font-medium text-text-4">{'−'}</p>;

  if (renderer === null) {
    return EMPTY_COLUMN;
  }

  const children = renderer?.props?.children;

  const hasPropsProperty = Object.prototype.hasOwnProperty.call(
    renderer,
    'props',
  );
  const hasChildrenProperty = Object.prototype.hasOwnProperty.call(
    renderer.props,
    'children',
  );

  if (
    (hasPropsProperty && hasChildrenProperty && !children) ||
    (isArray(children) && isEmpty(children))
  ) {
    return EMPTY_COLUMN;
  }

  return renderer;
}
